import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import useResize from '../utils/use-resize'
import useMedia from '../utils/use-media';
import style from './menu.module.scss'

const ifWindow = typeof window !== 'undefined';

const menuTemplate = (heightFactor, onClick) => [
  <svg
    key="svg-1"
    className={style.svgBackdrop}
    viewBox="0 0 200 200" preserveAspectRatio="none">

    <polygon
      points={`-150 -10    0 -10    200 ${10 / heightFactor}   -10 280     -150 280`}
      onClick={onClick}/>
  </svg>,

  <svg
    key="svg-2"
    width="30"
    height="30"
    className={style.menuIcon}
    viewBox="0 0 24 24"
    onClick={onClick}>

    <path fill="none" d="M0 0h24v24H0z"/>
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
  </svg>,

  <ul key="list">
    <li onClick={onClick} onKeyPress={onClick}>
      <h3>
        <Link className={style.hiddenLink} to={`/`}>Home</Link>
      </h3>
    </li>
    {/* <li>
      <h3>
        <Link className={style.hiddenLink} to={`/projects`}>Projects</Link>
      </h3>
    </li> */}
    <li onClick={onClick} onKeyPress={onClick}>
      <h3>
        <Link className={style.hiddenLink} to={`/about-me`}>About Me</Link>
      </h3>
    </li>
    <li onClick={onClick} onKeyPress={onClick}>
      <h3>
        <Link className={style.hiddenLink} to={`/posts`}>Posts</Link>
      </h3>
    </li>
  </ul>
];

const getHeightFactor = () => {
  if (!ifWindow) {
    return 1;
  }
  const factor = window.innerHeight / 946;
  if (factor < 0.66) {
    return 0.66;
  } else {
    return factor;
  }
};

let coldStart = true;

const Menu = ({className}) => {
  const [isSSR, setSSR] = useState(!ifWindow);
  const [open, setOpen] = useState(false);
  const [heightFactor, setHeightFactor] = useState(getHeightFactor());
  const mobile = useMedia(['(max-width: 600px)'], [true], false);

  useResize(() => setHeightFactor(getHeightFactor()))

  useEffect(() => {
    // keeps coldStart until the first browser side render
    coldStart = !ifWindow;
  })

  useEffect(() => setSSR(false), [isSSR])

  useEffect(() => setHeightFactor(getHeightFactor()), [heightFactor])

  if (isSSR) {
    return <xxx></xxx>;
  }

  if (mobile) {

    return (<div className={`${className} ${style.root}`}  style={{ '--height-factor': heightFactor }} cold-start={coldStart.toString()}>
      <div className={`${style.twister} ${open ? style.open : ''}`}>
        {menuTemplate(heightFactor, () => setOpen(!open))}
      </div>
    </div>);

  } else {

    return (<div className={`${className} ${style.root}`} style={{ '--height-factor': heightFactor }} cold-start={coldStart.toString()} >
      <div className={style.twister}>
        {menuTemplate(heightFactor)}
      </div>
    </div>);

  }
}

Menu.defaultProps = {
  className: '',
}

Menu.propTypes = {
  className: PropTypes.string,
}

export default Menu;