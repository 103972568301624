import { useEffect } from 'react';

const _window = typeof window !== 'undefined' ? window : null;

export default function useResize(cb) {
  useEffect(() => {
    if (_window) {
      _window.addEventListener('resize', cb)
      return () => _window.removeEventListener('resize', cb);
    }
  });
}