import Typography from 'typography'

const typography = new Typography({
  title: 'Inter var',
  baseFontSize: '16px',
  baseLineHeight: 1.5,
  scaleRatio: 5 / 2,
  headerFontFamily: ['Inter var', 'Inter', 'system-ui', 'sans-serif'],
  bodyFontFamily: ['Inter var', 'Inter', 'system-ui', 'sans-serif'],
  overrideThemeStyles: () => ({
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
  })
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale