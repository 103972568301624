import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { rhythm, scale } from '../utils/typography'
import {HackFontPrefetch} from './hack-font';
import Menu from './menu';
import DarkMode from './dark-mode'
import ErrorBoundary from './error-boundary'
import style from './layout.module.scss'

const hiddenLink = {
  boxShadow: `none`,
  textDecoration: `none`,
  color: `inherit`,
};

const titleStyle = {
  ...scale(1.5),
  marginBottom: rhythm(1.5),
  marginTop: 0,
}

const smallTitleStyle = {
  marginTop: 0,
}


const Layout = ({location, children, className, showMenu, goHome, smallTitle, title, loadHackFont}) => {

  const rootPath = `${__PATH_PREFIX__}/`
  const titleLink = goHome ? rootPath : location.pathname

  let titleItem;
  if (smallTitle) {
    titleItem = (<h3 style={smallTitleStyle}>
      <Link style={hiddenLink} to={titleLink}>{title}</Link>
    </h3>);
  } else {
    titleItem = (<h1 style={titleStyle}>
      <Link style={hiddenLink} to={titleLink}>{title}</Link>
    </h1>);
  }

  return (
    <ErrorBoundary>
      <div className={`${style.root} ${className} ${!showMenu ? style.rootNoMenu : ''}`}>
        { loadHackFont ? <HackFontPrefetch /> : null}
        <header className={style.header}>
          <DarkMode className={style.darkMode} />
          {titleItem}
        </header>
        { showMenu ? <Menu className={style.menu}/> : null }
        <main className={style.main}>{children}</main>
        <footer className={style.footer}>
          © {new Date().getFullYear()} - <a href="./">Manuel Rueda</a> - <a target="_blank" href="/rss.xml">rss</a> - <a target="_blank" href="/rss-en.xml">rss (only english)</a> - <a target="_blank" href="/rss-es.xml">rss (solo español)</a>
        </footer>
      </div>
    </ErrorBoundary>
  )
}

Layout.defaultProps = {
  showMenu: true,
  menuOpened: false,
  goHome: true,
  smallTitle: false,
  loadHackFont: false,
  className: '',
}

Layout.propTypes = {
  showMenu: PropTypes.bool,
  menuOpened: PropTypes.bool,
  goHome: PropTypes.bool,
  smallTitle: PropTypes.bool,
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  loadHackFont: PropTypes.bool,
}

export default Layout;