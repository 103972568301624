import React from 'react';
import useDarkMode from '../utils/use-dark-mode';
import useMedia from '../utils/use-media';
import style from './dark-mode.module.scss';

const DarkMode = ({className}) => {
  const [darkMode, setDarkMode] = useDarkMode()
  const isTouch = useMedia(['(hover:none), (hover:on-demand)'], [true], false);
  const buttonRef = React.createRef();
  const title = darkMode ? 'Change to Light Mode' : 'Change to Night Mode';

  const buttonClick = () => {
    if (isTouch) {
      buttonRef.current.addEventListener('animationend', (event) => event.currentTarget.classList.remove(style.forcedAnimation), { once: true })
      buttonRef.current.classList.add(style.forcedAnimation)
    }
  }

  return (<div role="button" tabIndex={-1} onClick={() => setDarkMode(!darkMode)} onKeyPress={() => setDarkMode(!darkMode)} className={`${style.root} ${className}`}>
    <button type="button" className={style.iconsWrapper} title={title} ref={buttonRef} onClick={isTouch ? buttonClick : null}>
      <svg className={`${style.moon} ${darkMode ? style.displayIcon : ''}`} width="24" height="24">
        <path d="M9 2c-1.05 0-2.05.16-3 .46 4.06 1.27 7 5.06 7 9.54 0 4.48-2.94 8.27-7 9.54.95.3 1.95.46 3 .46 5.52 0 10-4.48 10-10S14.52 2 9 2z"/>
        <path fill="none" d="M0 0h24v24H0z"/>
      </svg>

      <svg className={`${!darkMode ? style.displayIcon : ''}`} width="24" height="24">
        <path fill="none" d="M0 0h24v24H0z"/>
        <path d="M20 15.31L23.31 12 20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69zM12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z"/>
      </svg>
      <span className={style.text}>
        {darkMode ? 'Night Mode': 'Light Mode'}
      </span>
    </button>
  </div>)
}

export default DarkMode