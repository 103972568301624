import React from 'react'
import Helmet from 'react-helmet'

export const HackFontPrefetch = () => <Helmet>
  <link rel="preconnect" href="https://cdnjs.cloudflare.com"></link>

  <link rel="prefetch" as="font" crossorigin="crossorigin" type="font/woff2" href="https://cdnjs.cloudflare.com/ajax/libs/hack-font/3.003/web/fonts/hack-regular-subset.woff2?sha=3114f1256" />
  {/* <link rel="prefetch" as="font" crossorigin="crossorigin" type="font/woff" href="https://cdn.jsdelivr.net/npm/hack-font@3/build/web/fonts/hack-regular.woff?sha=3114f1256" />

  <link rel="prefetch" as="font" crossorigin="crossorigin" type="font/woff2" href="https://cdn.jsdelivr.net/npm/hack-font@3/build/web/fonts/hack-bold.woff2?sha=3114f1256" />
  <link rel="prefetch" as="font" crossorigin="crossorigin" type="font/woff" href="https://cdn.jsdelivr.net/npm/hack-font@3/build/web/fonts/hack-bold.woff?sha=3114f1256" />

  <link rel="prefetch" as="font" crossorigin="crossorigin" type="font/woff2" href="https://cdn.jsdelivr.net/npm/hack-font@3/build/web/fonts/hack-italic.woff2?sha=3114f1256" />
  <link rel="prefetch" as="font" crossorigin="crossorigin" type="font/woff" href="https://cdn.jsdelivr.net/npm/hack-font@3/build/web/fonts/hack-italic.woff?sha=3114f1256" />

  <link rel="prefetch" as="font" crossorigin="crossorigin" type="font/woff2" href="https://cdn.jsdelivr.net/npm/hack-font@3/build/web/fonts/hack-bolditalic.woff2?sha=3114f1256" />
  <link rel="prefetch" as="font" crossorigin="crossorigin" type="font/woff" href="https://cdn.jsdelivr.net/npm/hack-font@3/build/web/fonts/hack-bolditalic.woff?sha=3114f1256" /> */}

</Helmet>